:root {
  --black: #333333;
  --white: #ffffff;
  --offwhite: #f7f7f7;
  --primary: #455c58;
  --secondary: #3e5d58;
  --darkblue: #151f6d;
  --red: #d11a2a;
  --lhtpink: #ecc7cd;
  --yellow: #ecf0dd;
  --green: #9eb356;
  --purple: #aa0061;
  --gray: #e5e5e5;
  --fontxxxl: 22px;
  --fontxxl: 20px;
  --fontxl: 18px;
  --fontlg: 16px;
  --fontsm: 14px;
  --fontxs: 12px;
  --fontxxs: 10px;
  --fontrubik: 'Rubik', sans-serif;
  --fontmanrope: 'Manrope', sans-serif;
  --shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.Accordion-menu {
  background-color: #ebebeb !important;
  padding: 10px 0;
  height: 100vh;
}
.menu_search {
  margin: 10px;
  width: -webkit-fill-available;
}
.accordion-body.menu_list:hover {
  background-color: #8ea29f;
  color: #fff;
}
.accordion-item {
  background-color: transparent;
}
.accordion-button.collapsed {
  background-color: #455c58 !important;
  color: #fff !important;
  border-radius: 0 !important;
  margin: 10px 0;
}
.search_menu_list {
  padding: 1rem 1.25rem;
}
.search_menu_list:hover {
  background-color: #8ea29f;
  color: #fff;
}
.no_records {
  padding: 1rem 1.25rem;
  color: #8ea29f;
}
.menu_active {
  background-color: #8ea29f;
  color: #fff;
}
.accordion-menu-list {
  max-height: 100vh;
  overflow: auto;
}
.print_button {
  text-align: right;
  margin: 10px 0;
}
.print_button .btn {
  min-width: 47px !important;
  padding: 3px 3px;
}
.custom-menu-dots .dropdown-toggle {
  min-width: auto;
  margin: 0 10px;
  padding: 0;
  border: 1px solid #3e5d58;
  background: #f7f7f7;
  color: #3e5d58;
  width: 42px;
  height: 42px;
  border-radius: 5px;
}
.forms-create button {
  border-radius: 5px;
  padding: 1px 13px;
  font-size: 16px;
  margin: 0 15px 0 0;
}
.forms-filter button {
  margin-right: 15px;
}
.forms-create button svg {
  margin-right: 10px;
}
.custom-menu-dots .dropdown-toggle:after {
  content: none;
}
.menu-item {
  color: #fcc;
  padding: 3px;
}
.forms-managment-section {
  display: flex;
  padding: 40px 0;
}
.forms-managment-right {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.forms-filter button {
  border: 2px solid #3e5d58;
  color: #3e5d58;
  border-radius: 5px;
  padding: 0 12px !important;
  min-width: auto;
}
.forms-filter button img {
  margin-right: 10px;
}
.form-management-middle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.forms-managment-left h6 {
  font-weight: 700;
  font-size: 25px;
  margin: 0;
}
.forms-search {
  position: relative;
  margin-right: 15px;
}
.forms-search .form-control {
  padding: 9px 15px 9px 50px;
  height: auto;
  color: rgba(69, 92, 88, 0.5);
  border: none;
  border-radius: 5px;
  background: rgb(196 196 196 / 15%);
}
.forms-search .form-control:focus {
  background: rgb(196 196 196 / 15%);
}
.forms-search .form-control::placeholder {
  color: rgba(69, 92, 88, 0.5);
}
.forms-icon {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.custom-menu-dots .dropdown-toggle:focus,
.custom-menu-dots .dropdown-toggle:hover {
  background: 0 0 !important;
  color: #3e5d58 !important;
  border-color: #3e5d58 !important;
}
.forms-filter button:focus,
.forms-filter button:hover {
  background-color: #fff;
  color: #3e5d58;
  border: 2px solid #3e5d58;
}
.forms-create button:active,
.forms-create button:focus,
.forms-create button:hover {
  background-color: #3e5d58 !important;
  box-shadow: none !important;
}
button:focus {
  box-shadow: none !important;
}
.content-toogle .dropdown-toggle {
  width: auto;
  min-width: auto;
  padding: 0;
  background: 0 0;
  border: none;
  color: #909090;
  width: 20px;
}
.content-toogle .dropdown-toggle:after {
  content: none;
}
.forms-content {
  background-color: #f7f7f7;
  border-radius: 16px;
}
.date-line {
  margin-top: 0 !important;
  margin-bottom: 24px !important;
}
.content-title-section {
  margin-left: 15px;
}
.content-toogle {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.content-toogle .user-img {
  margin-right: 20px;
  position: relative;
}
.user-img span {
  position: absolute;
  top: -1px;
  right: -3px;
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background-color: #d11a2a;
  font-size: 9px;
  font-weight: 500;
  color: #fff;
  text-align: center;
  line-height: 13px;
}
.red-date {
  color: #d11a2a !important;
}
.content-title-section h4 {
  font-weight: 600;
  font-size: 14px;
  color: rgba(51, 51, 51, 0.7);
  margin: 0;
}
.content-toogle .dropdown-toggle:focus,
.content-toogle .dropdown-toggle:hover {
  background: 0 0 !important;
  color: #909090 !important;
}
.new-form-title {
  margin: 25px 0;
}
.forms-content {
  margin-bottom: 24px;
}
.new-form-title {
  margin: 25px 0;
}
.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: #f7f7f7;
  box-shadow: none;
  border-color: #e5e5e5;
  color: #333;
  border-radius: 5px;
  height: 45px;
}
.content-title-section h6 {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 1px;
  color: #000;
  word-break: break-all;
  text-align: left;
  white-space: nowrap;
  width: 175px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.content-icon-section img {
  width: 50px;
  height: 50px;
}
.theme-light {
  background: 0 0 !important;
  border: none;
  color: #000 !important;
}
.theme-light:hover {
  color: #000;
}
.content-icon {
  border: none;
}
.new-form-radio {
  display: flex;
}
.new-form-radio-box label {
  display: flex;
  align-items: center;
}
.new-form-radio-box label p {
  margin: 0;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333;
}
.new-form-radio-box {
  margin-right: 30px;
}
.new-form-radio-box .radio-round {
  width: 26px;
  height: 26px;
  background: #f7f7f8;
  flex: 0 0 26px;
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 100%;
  margin-right: 15px;
  position: relative;
}
.new-form-radio-box input {
  display: none;
}
.new-form-radio-box input:checked + .radio-round:before {
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  border-radius: 100%;
  background: #aa0061;
}
.nav-tabs .nav-link.active,
.tab-section .nav-tabs .nav-item.show .nav-link {
  color: #aa0061 !important;
  border: none;
  border-bottom: 3px solid #aa0061 !important;
  font-weight: 700 !important;
}
.tab-section .nav-tabs .nav-link {
  font-family: 'Noto Sans';
  font-size: 18px !important;
  line-height: 25px;
  color: rgb(51 51 51 / 50%);
  font-weight: 500 !important;
  border: 2px transparent;
}
.tab-created .nav-tabs .nav-link {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: var(--primary);
  background: 0 0;
  border: 2px solid var(--primary);
}
.tab-section .nav-tabs {
  border: 2px transparent;
}
.mynewForm {
  font-size: 30px;
  font-weight: 700;
  color: #333;
  padding: 0 12px;
  margin-bottom: 0;
}
.mynewForm-heading {
  display: flex;
  align-items: center;
  margin-top: 39px;
  margin-bottom: 20px;
}
.mynewForm-heading button {
  background-color: transparent;
  border: none;
  padding: 0;
  min-width: 4px !important;
}
.mynewForm-heading button:active,
.mynewForm-heading button:focus,
.mynewForm-heading button:hover {
  background-color: transparent !important;
  border: none;
}
.myform-details {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 29px;
  opacity: 0.5;
}

.toogle-swich .switch {
  appearance: none;
  width: 50px;
  height: 24px;
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
  /* background-image: radial-gradient(circle 15px, #fff 100%, #0000 0), radial-gradient(circle 15px, #0003 0, #0000 100%);*/
  background-image: url(circle.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  transition: background 0.2s ease-out;
  background-color: #c4c4c4;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.25);
}

.toogle-swich .switch:checked {
  background-position: 26px 0;
  background-color: #9eb356;
  box-shadow: inset 0 0 4px rgb(0 0 0 / 25%);
}

.toogle-swich {
  line-height: 0;
}
.add-g button {
  background: 0 0;
  padding: 0;
  border: none;
  color: #151f6d;
  font-size: 14px;
  font-weight: 400;
  min-width: 0;
}
.add-g button svg {
  margin-right: 5px;
}
.add-g button:active,
.add-g button:focus,
.add-g button:hover {
  background-color: transparent !important;
  border: none;
  color: #151f6d;
}
.required p {
  margin: 0;
  color: #333;
  font-size: 14px;
  font-weight: 400;
  padding: 0 10px 0 20px;
  opacity: 0.5;
}
.add-group-t-button {
  display: flex;
  align-items: center;
  justify-content: end;
}
.apply-condition button {
  background: 0 0;
  padding: 0;
  border: none;
  color: #151f6d;
  font-size: 14px;
  font-weight: 400;
  min-width: 0;
}
.apply-condition button:active,
.apply-condition button:focus,
.apply-condition button:hover {
  background-color: transparent !important;
  border: none;
  color: #151f6d;
}
.apply-section {
  padding-top: 20px;
}
.formlabel {
  color: #455d58 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-bottom: 5px !important;
  line-height: 0 !important;
}
.my-new-formsection {
  background-color: #f7f7f7;
  border-radius: 16px;
  padding: 20px;
  margin: 20px 0;
}
.my-new-formsection input[type='text'],
.my-new-formsection select,
[type='number'] {
  background-color: #fff !important;
  border: 1px solid #4242421a !important;
  border-radius: 5px !important;
}
.my-form-input input {
  padding: 11px 45px 11px 20px;
}
.my-form-input input::placeholder {
  color: #333;
  font-size: 16px;
}
.my-form-input {
  position: relative;
}
.input-img {
  position: absolute;
  top: 6.5px;
  right: 5px;
}
.text-answer-div {
  position: relative;
}
.input-text-img {
  position: absolute;
  top: 10.5px;
  left: 14px;
}
.text-answer-div select {
  padding-left: 35px;
}
.multiselect-box .optionListContainer {
  z-index: 999;
}
.remove-button {
  text-align: right;
}
.remove-button button {
  background-color: transparent;
  color: red;
  border: none;
  min-width: 0;
  padding: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  margin-left: auto;
}
.remove-button button:active,
.remove-button button:focus,
.remove-button button:hover {
  color: red !important;
}
.remove-button button img {
  margin-right: 5px;
}
.delete-icon {
  position: absolute;
  top: 10px;
  right: 13px;
}
.my-form-input input {
  margin-bottom: 20px;
}
.apply-condition button {
  margin-right: 20px;
}
.button {
  text-align: center;
}
.add-q button {
  color: var(--primary);
  background-color: var(--white);
  border-color: var(--primary);
}
.add-q button:hover {
  color: var(--white);
  background-color: var(--primary);
  border-color: var(--primary);
}
.add-q {
  text-align: right;
}
.add-q {
  margin-bottom: 36px;
}
.preview {
  background-color: transparent !important;
  border: 1px solid #3e5d58 !important;
  color: #3e5d58 !important;
  font-size: 16px !important;
  margin-right: 20px !important;
}
.applyCondition-modal .modal-lg {
  max-width: 641px;
}
.modal-heading {
  font-size: 20px;
  font-weight: 800 !important;
  color: #333;
  margin: 0 !important;
}
.modal-header {
  padding: 24px 30px;
}
.back {
  border: none !important;
  background-color: transparent !important;
  color: #333 !important;
  min-width: 100px !important;
}
.modal-footer {
  padding: 15px 30px;
}
.applyCondition-modal .modal-lable {
  font-size: 16px;
  font-weight: 700;
}
.modal-m-lable {
  padding-bottom: 15px;
}
.text-answer-div select {
  margin-bottom: 20px;
}
.modal-remove-icon {
  position: inherit;
  top: -11px;
  right: 0;
  margin-left: 14px;
}
.my-form-input-modal {
  display: flex;
  align-items: center;
}
.modal-body {
  padding: 24px 30px;
}
.select-user-modal .modal-body {
  max-height: 50vh;
  overflow: auto;
}
.select-user-modal .modal-body::-webkit-scrollbar {
  width: 5px;
}
.select-user-modal .modal-body::-webkit-scrollbar-thumb {
  background: var(--primary);
}
.modal-xlg,
.select-section-modal .modal-lg {
  max-width: 373px !important;
}
.modal-two-check .container {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  text-transform: capitalize;
}
.modal-two-check .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.modal-two-check .container:hover {
  background-color: transparent;
}
.modal-two-check .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #f7f7f8;
  border: 1px solid #42424263;
  text-transform: capitalize;
  font-size: 18px;
  border-radius: 4px;
}
.modal-two-check .container:hover input ~ .checkmark {
  background-color: #f7f7f8;
}
.modal-two-check .container input:checked ~ .checkmark {
  background-color: #f7f7f8;
}
.modal-two-check .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.modal-two-check .container input:checked ~ .checkmark:after {
  display: block;
}
.modal-two-check .container .checkmark:after {
  left: 8px;
  top: 3.5px;
  width: 8px;
  height: 12px;
  border: solid #aa0061;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.modal-two-footer {
  margin-right: auto;
}
.done {
  min-width: 100px !important;
  border-radius: 5px !important;
}
.right-button {
  min-width: 42px !important;
  padding: 1px 12px !important;
  margin-left: 10px;
  border-radius: 5px !important;
}
.three-modal-footer {
  width: 100%;
}
.form-settings-modal .modal-title {
  display: flex;
  align-items: center;
}
.form-settings-modal .modal-title img {
  margin-right: 10px;
}
.default-arrow-select select {
  appearance: none;
  background-image: none;
}
.default-arrow-select:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-image: url(../../../public/img/down-arrow.svg);
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  pointer-events: none;
  z-index: 9;
}
.form-settings-modal > div {
  min-width: 1147px !important;
}
.default-arrow-select {
  position: relative;
}
.multiselect-box .searchWrapper {
  background: #f7f7f7;
  border-color: #e5e5e5;
  min-height: 45px;
  padding: 4px 4px !important;
  margin-bottom: 10px;
}
.multiselect-box .searchWrapper input::placeholder {
  color: #333 !important;
}
.multiselect-box .searchWrapper input {
  border: none !important;
  padding: 6px 8px !important;
  background: 0 0 !important;
  margin: 0 !important;
  box-shadow: none !important;
}
.multiselect-box .searchWrapper input::placeholder {
  color: #333 !important;
}
.multiselect-box .chip {
  border: 1px solid #aa0061;
  border-radius: 100px;
  background: rgb(170 0 97 / 20%);
  color: #aa0061;
  position: relative;
  padding: 8px 40px 8px 20px;
  margin: 4px 4px;
}
.forms-content-section .row > div.col-lg-12 {
  flex: 0 0 auto !important;
  width: 100% !important;
}
.multiselect-box .chip:before {
  content: '';
  position: absolute;
  background-image: url(../../../public/img/x-icon.svg);
  width: 7px;
  height: 7px;
  top: 50%;
  transform: translateY(-50%);
  right: 11px;
  pointer-events: none;
}
.multiselect-box .chip .closeIcon {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  right: 11px;
}
.multiselect-box .highlightOption {
  background: 0 0;
  color: #000;
}
.multiselect-box .optionContainer li:hover {
  background: #aa0061;
}
.responses-collapse .accordion-item {
  border: none;
  background: #f7f7f7 !important;
  border-radius: 16px !important;
  overflow: hidden;
}
.responses-collapse .accordion-item .accordion-button {
  background-color: rgb(158 179 86 / 20%) !important;
  margin: 0;
  box-shadow: none !important;
  padding: 15px;
}
.responses-collapse .accordion-item .accordion-button.collapsed {
  background-color: #f7f7f7 !important;
}
.responses-collapse .accordion-body {
  border: 1px solid rgb(51 51 51 / 10%);
  border-top: none;
  border-radius: 0 0 16px 16px;
  padding: 30px;
}
.responses-collapse .accordion-item + .accordion-item {
  margin-top: 15px;
}
.responses-header-row {
  display: flex;
  align-items: center;
  width: 100%;
}
.responses-header-left {
  display: flex;
  align-items: center;
}
.response-header-left-line {
  border-right: 1px solid #bababa;
  padding-right: 10px;
}
.responses-header-image {
  width: 61px;
  border-radius: 10px;
  height: 61px;
  overflow: hidden;
  box-shadow: 0 0 10.6667px rgba(0, 0, 0, 0.11);
  border: 3px solid #fff;
}
.responses-header-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.responses-header-detail {
  padding-left: 20px;
}
.responses-header-detail h5 {
  margin: 0;
  color: #333;
  font-weight: 800;
  font-size: 18px;
}
.responses-header-detail h6 {
  margin: 3px 0 0 0;
  color: #151f6d;
  font-weight: 500;
}
.responses-header-detail h6 span {
  color: rgba(51, 51, 51, 0.5);
}
.responses-header-right p {
  margin: 0;
  color: rgba(51, 51, 51, 0.7);
  opacity: 0.6;
  text-align: right;
}
.responses-header-right {
  margin-left: auto;
  padding-right: 35px;
}
.edit-icon-form {
  margin-left: 6px;
}
.edit-icon-form svg {
  width: 14px;
}
.responses-collapse .accordion-item .accordion-button:after {
  filter: brightness(70) invert(0.5) !important;
}
.forms-managment-left p {
  margin: 0;
  color: #333;
  font-weight: 500;
}
.responses-forms-header-section .forms-filter {
  margin-right: 15px;
}
.responses-content-question {
  display: flex;
  align-items: center;
}
.responses-content-question h6 {
  margin: 0;
}
.responses-content-question span {
  width: 30px;
  height: 30px;
  flex: 0 0 30px;
  background: #aa0061;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  margin-right: 10px;
}
.responses-content-question {
  margin-bottom: 15px;
}
.responses-content-answer {
  display: flex;
  align-items: flex-start;
}
.responses-content-answer img {
  margin: 0 13px 0 3px;
}
.responses-content-answer p {
  color: #333;
  margin: 0;
}
.responses-content-box + .responses-content-box {
  margin-top: 25px;
}
.response-margin {
  margin-top: 45px;
}
.content-wrap-title {
  margin-bottom: 20px;
  color: #000;
  font-size: 18px;
  border-bottom: 1px solid rgb(51 51 51 / 10%);
  padding-bottom: 15px;
}
.answer-files img {
  margin: 0;
  width: 50px;
  height: 50px;
  border-radius: 14px;
}
.answer-files {
  display: flex;
  align-items: center;
}
.answer-files h4 {
  font-size: 14px;
  margin: 0 0 0 15px;
}
.responses-collapse {
  padding-bottom: 60px;
}
.my-new-formsection .invalid-feedback {
  margin-top: -17px;
  margin-bottom: 9px;
}
.my-new-formsection input.form-control.is-invalid {
  background-image: none;
}
.my-new-formsection button.right-button.btn.btn-primary {
  background-color: #455c58;
}
.tree {
  margin: 0;
  padding: 0;
  list-style: none;
}
.tree ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-left: 8px;
  position: relative;
}
.tree ul ul {
  margin-left: 8px;
}
.tree ul:before {
  content: '';
  display: block;
  width: 0;
  position: absolute;
  top: -15px;
  bottom: 4px;
  left: 0;
  border-left: 1px solid rgba(51, 51, 51, 0.2);
}
.tree ul li:before {
  content: '';
  display: block;
  width: 14px;
  height: 0;
  border-top: 1px solid rgba(51, 51, 51, 0.2);
  margin-top: -1px;
  position: absolute;
  top: 0.8em;
  left: 0;
}
.tree ul li:last-child:before {
  height: auto;
  top: 1em;
  bottom: 0;
}
.tree li {
  margin: 0;
  padding: 0 1em;
  line-height: 2em;
  color: #369;
  font-weight: 700;
  position: relative;
}
.tree li .expand {
  display: block;
}
.tree li .collapse {
  display: none;
}
.tree li.branch > a {
  text-decoration: none;
  color: #333;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  white-space: nowrap;
  width: 210px;
  vertical-align: sub;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tree li.branch > a img {
  margin-right: 10px;
}
.tree li.branch > .title_active a {
  color: #aa0061;
}
.tree li.branch > a.tree-title {
  color: #aa0061;
}
.title_active img {
  width: 15px;
  height: 15px;
}
.branch > ul li {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(51, 51, 51, 0.5);
  cursor: pointer;
}
.branch > ul li a.tree_active {
  color: #9eb356;
}
.branch > ul li * {
  color: rgba(51, 51, 51, 0.5);
}
.tree-title {
  color: #aa0061;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}
.tree li button {
  text-decoration: none;
  color: #369;
  border: none;
  background: 0 0;
  margin: 0;
  padding: 0;
  outline: 0;
}
.tree li button:active {
  text-decoration: none;
  color: #369;
  border: none;
  background: 0 0;
  margin: 0;
  padding: 0;
  outline: 0;
}
.tree li button:focus {
  text-decoration: none;
  color: #369;
  border: none;
  background: 0 0;
  margin: 0;
  padding: 0;
  outline: 0;
}
.indicator {
  margin-right: 5px;
}
li.branch > img {
  margin-right: 16px;
  position: absolute;
  left: 16px;
  top: 8px;
}
li.branch:before {
  content: '';
  position: absolute;
  height: 1px;
  width: 20px;
  background-color: rgba(51, 51, 51, 0.2);
  top: 15px;
  left: 29px;
}
li.branch {
  position: relative;
  margin: 15px 0;
  text-transform: capitalize;
  padding-left: 48px;
}
.branch > ul li {
  margin: 9px 0;
}
.tree_view_search {
  border: none;
  background-color: #f7f7f7;
  padding-left: 40px;
}
.tree_view_search:focus {
  background-color: #f7f7f7;
}
.tree_search_box {
  position: relative;
}
.tree_search_box img {
  position: absolute;
  top: 11px;
  left: 11px;
}
.tree_wrp {
  background-color: #f7f7f7;
  padding: 40px 34px;
  height: 100%;
  margin-left: -40px;
  min-height: 600px;
}
.bottom_button {
  display: flex;
  gap: 20px;
  justify-content: end;
  margin-bottom: 10px;
  margin-top: -32px;
}
.create_model_bar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 40px;
}
.create_model_bar button {
  border-radius: 5px;
  padding: 1px 13px;
  font-size: 16px;
  margin: 0 15px 0 0;
}
.image_banner {
  width: 100%;
  height: 100%;
  border-radius: 11.42px;
  overflow: hidden;
  margin: 0 auto;
}
.image_banner img {
  object-fit: cover;
  width: 100%;
}
.logo-column-pdf {
  margin-bottom: 2rem;
}
.module_title {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.module_title h3 {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #aa0061;
  margin-bottom: 0;
}
.module_title span {
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #333;
}
.description_wrp .table > table {
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.description_wrp .table > tbody,
td,
th,
tr {
  border-width: 1px;
  padding: 15px;
}
span.module_dot {
  margin: 0 15px;
}
.print .module_title .title {
  color: #aa0061;
}
.reference_videos {
  border-radius: 30px;
  overflow: hidden;
  position: relative;
}
.reference_videos .vidico {
  position: relative;
  height: 329px;
  width: 100%;
}
.reference_videos .vidico:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  background: url('../../../public/img/video_icon.png') no-repeat center center,
    linear-gradient(179.99deg, rgba(0, 0, 0, 0) -19.77%, #333 99.99%);
  background-size: initial;
}
button.vidico {
  padding: 0;
}
button.vidico img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video-modal .modal-content {
  background: #fff;
  border: none;
  border-radius: 10px;
}
.video-modal .modal-header {
  border: none;
  padding: 0;
}
.video-modal .close {
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  z-index: 999;
  font-size: 40px;
  font-weight: 400;
  color: #fff;
  opacity: 1;
  text-shadow: none;
  background: #a2b062;
  padding: 0;
}
.video-modal .modal-body {
  position: relative;
  padding: 0;
  border-radius: 10px;
  overflow: hidden;
  flex: 1 1 auto;
}
.module_video_model button.btn-close {
  background-color: #3e5d58;
  z-index: 1;
  opacity: 1;
  border-radius: 100px;
  width: 20px;
  height: 20px;
  overflow: hidden;
  position: absolute;
  right: -5px;
  top: -5px;
}
.module_video_model .modal-header h2 {
  color: #3e5d58;
  padding: 10px;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
}
.module_video_model .btn-close {
  background-image: url('../../../public/img/close_icons.png') !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}
.video_title h6 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #fff;
  margin-bottom: 0;
}
.video_title {
  position: absolute;
  bottom: 29px;
  padding: 0 15px;
}
.related_files .forms-content {
  margin-top: 20px;
}
.related_files .content-title-section h4 {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: rgba(51, 51, 51, 0.7);
  text-align: left;
}
.reference_wrp h1,
.related_files h1 {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #333;
}
button.forms-content.create-other {
  border: none;
  min-width: 300px;
}
.new_module .formlabel.form-label {
  margin-bottom: 8px !important;
  line-height: normal !important;
  background-color: transparent !important;
  border: none !important;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #455d58;
}
.new_module .form-control {
  margin-bottom: 15px;
}
.new_module .add_fields {
  display: flex;
  align-items: flex-end;
}
.new_module .add_fields button {
  background-color: transparent;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #151f6d;
  border: none;
}
.new_module .add_fields button:active,
.new_module .add_fields button:focus {
  background-color: transparent !important;
  color: #151f6d !important;
}
.new_module .App {
  margin-bottom: 40px;
}
.new_module .app .product-preview {
  height: 150px;
}
.new_module .upload_cover_box {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 20px;
}
.new_module .cover_image {
  width: 80px;
  height: 80px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
}
.upload_cover_box .btn {
  min-width: auto;
  padding: 0;
}
.new_module .cover_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.new_module .add_image_box {
  width: 145px;
  height: 42px;
  background: #fff;
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 5px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
.new_module .add_image_box span {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: rgba(51, 51, 51, 0.6);
}
input.add_image_input.form-control {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
}
.new_module .remove_bin {
  color: #d11a2a;
  padding: 0;
}
.new_module .new_module .add_image_box span img {
  margin-right: 10px;
}
.new_module .cover_video_icon {
  position: absolute;
}
.new_module .cover_image {
  position: relative;
}
.new_module .video_reference .cover_image::before {
  content: '';
  background-image: url(../../../public/img/video_icon.png);
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background-repeat: no-repeat;
  background-position: center;
  z-index: 99;
  height: 20px;
  width: 20px;
  background-size: contain;
  cursor: pointer;
}
.upload_related_files {
  margin-top: 40px;
}
.new_module .upload_related_box .btn {
  padding: 0;
  min-width: auto;
}
.new_module .upload_related_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  background-color: #f7f7f7;
  padding-right: 15px;
  border-radius: 20px;
  width: fit-content;
}
.new_module .upload_related_box .forms-content {
  margin-bottom: 0;
  padding: 15px;
}
.upload_related_box .content-icon-section {
  width: 50px;
  height: 50px;
}
.new_module .bottom_button {
  margin-top: 66px;
  justify-content: center;
}
.new_sub_module {
  color: #f7f7f8;
}
.ck.ck-editor__editable_inline p {
  color: var(--black);
}
.form_info {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.print {
  padding: 20px 0 !important;
}
.ck-editor__editable {
  min-height: 200px;
}
.tree_header {
  display: flex;
  align-items: center;
}
button.add_operating_button.btn.btn-primary {
  line-height: 35px;
  min-width: inherit;
  padding: 4px 15px;
  border-radius: 5px;
  margin: 0 0 0 10px;
}
.tree_search_box .tree_view_search {
  background-color: #f0f0f0;
  margin-right: 10px;
}
.user-roles-box {
  display: flex;
}
.modal-two-check.user-roles-box .container {
  padding-right: 5px;
}
.select-with-plus {
  display: flex;
  align-items: center;
}
.select-with-plus > div {
  width: 100%;
}
.select-users-header .btn-primary {
  background-color: transparent;
  border: none;
  padding: 0;
  min-width: 4px !important;
  margin-right: 10px;
}
.select-user-modal .modal-dialog {
  max-width: 700px;
}
.select-user-image-name img {
  width: 55px;
  border-radius: 100px;
}
.select-user-image-name {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.select-user-image-name h6 {
  margin: 0;
  padding-left: 30px;
  font-weight: 500;
}
.select-user-role h6 {
  margin: 0;
}
.select-user-list-row {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  border-radius: 10px;
  box-shadow: 4px 4px 17px -1px #dbdbdb;
  margin-top: 15px;
}
.main-lable {
  box-shadow: none;
}
.main-lable-img h6 {
  padding-left: 0 !important;
}
.select-user .modal-content {
  border: none;
  border-radius: 7px;
}
.select-user {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.507);
}
.btn-btn-outline {
  border-color: #455c58 !important;
  margin-left: auto;
}
.btn-close {
  margin: 0 !important;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.t-switch p {
  margin: 0;
  padding: 0;
  margin-right: 20px;
  color: #d3d3d3;
  font-size: 12px;
}
.t-switch {
  display: flex;
  align-items: center;
}
.modal-top-containt {
  padding: 15px 33px !important;
  padding-bottom: 0 !important;
}
.modal-bottom {
  padding: 15px 33px !important;
  padding-top: 0 !important;
}
.setting-heading h2 {
  margin: 0;
  padding-top: 15px;
}
hr {
  margin: 0 !important;
  margin: 15px 0 !important;
  border: none;
  background: #d3d3d3;
}
.f-c-modal button {
  margin-left: auto !important;
}
.select-user-role.text-capitalize h6 {
  text-align: left;
}
.s-modal-left {
  width: 65%;
  display: flex;
  align-items: center;
}
.select-user-role.main-lable-heading {
  width: 100%;
}
.s-modal-heading {
  width: 180%;
}
.s-modal-heading h6 {
  font-weight: 400;
  color: gray;
}
.select-user-role h6 {
  font-weight: 400;
  color: gray;
}
.f-c-modal {
  border-bottom: none;
  padding-bottom: 0;
}
.filter-button {
  right: 17px;
}
.select-role-wrp {
  margin-top: 20px;
}
.filter-wpr label,
.select-role-wrp label {
  font-size: 18px;
  color: #3e5d58;
  margin-bottom: 10px;
  display: block;
}
.select-check-wrp {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  position: relative;
  flex-wrap: wrap;
  gap: 10px;
}
.select-check-wrp .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.select-check-wrp .form-group label {
  position: relative;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 100px;
  font-size: 16px;
  width: 100%;
  text-align: center;
  border: 1px solid #ccc;
}
.select-check-wrp .form-group input:checked + label {
  background: #ecf0dd;
  border: 1px solid #9eb356;
  color: #9eb356;
}

.filter-user-modal .modal-title {
  color: #949494;
  font-weight: 400 !important;
  font-size: 18px;
}
.filter-user-modal .modal-dialog {
  max-width: 550px;
}
.dropdown-item.active,
.dropdown-item:active {
  text-decoration: none;
  color: #b10061;
  background-color: #fff;
}
.user-search {
  position: absolute;
  top: 20%;
  left: 11px;
}
.searchBox {
  background: #fff !important;
  padding-left: 40px;
}
.form_setting {
  margin-top: 44px;
}
.form_setting_title {
  display: flex;
  align-items: center;
}
.form_setting_title h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #333;
  margin-bottom: 0;
  margin-left: 13px;
}
.form_setting .accordion-header .accordion-button {
  background-color: rgb(217 217 217 / 20%) !important;
  margin: 0;
  padding: 25px;
}
.form_setting .accordion-body {
  background-color: rgb(217 217 217 / 20%) !important;
  padding: 29px 25px 20px 25px;
}
.form_setting .accordion-button:not(.collapsed)::after {
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e');
  width: 1.25rem;
  height: 1.25rem;
}
.accordion-button:not(.collapsed)::after {
  filter: inherit !important;
}
.form_setting_fields label {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #455d58;
}
.form_setting_fields input {
  background: #f7f7f8;
  border: 1px solid rgba(66, 66, 66, 0.1);
  border-radius: 5px;
  -webkit-appearance: none;
}
.form_setting_fields input[type='date']::-webkit-calendar-picker-indicator,
.form_setting_fields input[type='date']::-webkit-inner-spin-button,
.form_setting_fields input[type='time']::-webkit-calendar-picker-indicator,
.form_setting_fields input[type='time']::-webkit-inner-spin-button {
  opacity: 0;
}
.form_fields_box {
  position: relative;
}
.form_fields_box .form_fields_icon {
  position: absolute;
  right: 5%;
  top: 50%;
  pointer-events: none;
}
.applicable_section_card {
  background: rgb(144 144 144 / 10%);
  border-radius: 15px;
  padding: 12px 30px 30px;
  margin-top: 30px;
}
.sharing-title p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: right;
  color: #333;
  opacity: 0.5;
  margin: 0;
}
.sharing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.applicable_section .form_label_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #455d58;
  padding-top: 17px;
}
.user_role_table {
  margin-top: -12px;
}
label.form_label_title.form-label {
  margin: 0;
}
.form-label {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  font-family: var(--fontrubik) !important;
  margin-bottom: 0.5rem !important;
}
.sharing_section {
  margin-top: 46px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.submissions_section {
  margin-top: 36px;
}
.submissions_section .form-label {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #455d58;
}
.user_role_table table {
  margin-top: 18px;
}
thead.table_title {
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #fff;
}
thead.table_title th {
  margin: 0;
  padding: 0;
  padding-top: 17px;
  padding-left: 16px;
  padding-bottom: 17px;
}
thead.table_title th {
  margin: 0;
  padding: 0;
  padding-top: 17px;
  padding-left: 0;
  padding-bottom: 17px;
  background: #455d59;
  text-align: center;
}
.description_wrp .table > tbody,
td,
th,
tr {
  border-width: 1px;
  padding: 15px;
}
thead.table_title tr th:first-child {
  border-top-left-radius: 8px;
  padding-left: 16px;
  text-align: left;
}
.user_role_table tr td {
  padding: 12px 11px !important;
  font-size: 14px;
  color: #333;
}
thead.table_title tr th:last-child {
  border-top-right-radius: 8px;
}
thead.table_title tr {
  border: 0;
}
.table > :not(:first-child) {
  border-top: 0 solid currentColor;
}
tbody tr {
  background-color: #fff;
}
td.input_checkbox {
  text-align: center;
}
td ul {
  margin: 0;
}
li.child_tag {
  margin: 0;
  padding: 0;
}
.child_table_row {
  background-color: #d9d9d9;
}
.checkbox_box_section {
  display: flex;
  justify-content: space-around;
}
.ignatories_input_checkbox {
  display: flex;
  align-items: center;
  gap: 15px;
}
.ignatories_input_checkbox p {
  margin: 0;
}
.footer_modal .container {
  display: block;
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  text-transform: capitalize;
}
.footer_modal .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.footer_modal .container:hover {
  background-color: transparent;
}
.footer_modal .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 26px;
  width: 26px;
  background-color: #f7f7f8;
  border: 1px solid #42424263;
  text-transform: capitalize;
  font-size: 18px;
  border-radius: 4px;
}
.footer_modal .container:hover input ~ .checkmark {
  background-color: #f7f7f8;
}
.footer_modal .container input:checked ~ .checkmark {
  background-color: #f7f7f8;
}
.footer_modal .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.footer_modal .container input:checked ~ .checkmark:after {
  display: block;
}
.footer_modal .container .checkmark:after {
  left: 8px;
  top: 3.5px;
  width: 8px;
  height: 12px;
  border: solid #aa0061;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.footer_user-roles-box {
  display: flex;
  gap: 60px;
}
.footer_modal_checkbox {
  margin-left: 16px;
}
.signatories_section {
  background: #d9d9d9;
  padding: 16px 16px 23px 21px;
}
.signatories_section {
  background: #d9d9d9;
  padding: 16px 16px 23px 21px;
  border-radius: 10px;
}
.main_checkbox_second {
  display: flex;
}
.applicable_section .sharing_section {
  margin-top: 33px;
}
.tab-created .nav-link {
  padding: 12px 17px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #333;
  background: #d0d0d0;
  border-radius: 5px;
  margin-right: 9px;
}
.tab-created .nav-link.active {
  background: var(--primary);
  border-radius: 5px;
  color: #fff !important;
  border-bottom: none !important;
}
.create-other {
  display: flex;
  align-items: center;
  padding: 15px 20px 16px 20px;
  border-bottom: 1px solid #7a7a7a30;
  cursor: pointer;
}
.create-by-heading h5 {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  color: rgba(51, 51, 51, 0.5);
  margin: 0;
  margin-left: 7px;
}
.user-name-other p {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333;
  margin: 0;
  margin-left: 11px;
}
.user-name-other span {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: rgba(51, 51, 51, 0.5);
}
.create-by {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px 8px 20px;
}
.user-name-other {
  display: flex;
  align-items: center;
}
.page_title {
  margin-top: 20px;
  margin-bottom: 21px;
}
.page_title h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #333;
  margin: 0;
}
.create_by_me_list .forms-content.create-other {
  border-bottom: none;
}
.create_by_me_list .create-other {
  border-bottom: none;
}
.content-title-section .due_date {
  color: #d11a2a;
}
.responses_model .modal-dialog {
  min-width: 731px;
}
.responses_model .modal-header .modal-title {
  display: flex;
  align-items: center;
  gap: 13px;
}
.responses_model .modal-header .modal-title h1 {
  margin: 0;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #333;
}
.user_box {
  display: flex;
  padding: 14px;
  align-items: center;
}
.table_head .user_box > div {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333;
  opacity: 0.7;
}
.user_list_wrp .user_box .user_name {
  width: 35%;
}
.user_list_wrp .user_box .user_role {
  width: 25%;
}
.user_list_wrp .user_box .date {
  width: 40%;
}
.table_body .user_box {
  background: #fff;
  box-shadow: 0 2px 15px rgba(51, 51, 51, 0.08);
  border-radius: 12.1116px;
  padding: 14px;
}
.table_body .user_box:last-child {
  margin-bottom: 0;
}
.table_body .user_box {
  margin-bottom: 13px;
}
.table_body .user_box .user_profile {
  display: flex;
  align-items: center;
  gap: 24px;
}
.table_body .user_box .user_profile img {
  width: 44px;
  height: 44px;
  border-radius: 100%;
  overflow: hidden;
  object-fit: cover;
}
.table_body .user_box .user_profile h4 {
  margin: 0;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #333;
}
.table_body .user_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table_body .user_detail h4 {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: rgba(51, 51, 51, 0.5);
  margin: 0;
}
.table_body .user_detail button {
  border: 1px solid #3e5d58;
  padding: 13px 14px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #3e5d58;
  background-color: transparent;
  border-radius: 8px;
}
.sub_check_box h2 {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #333;
}
.sub_check_box {
  margin-left: 76px;
}
.modal_check_box {
  display: flex;
  flex-wrap: wrap;
}
.sub_check_box_list .modal_check_box .modal-two-check label {
  font-size: 14px;
  min-width: 175px;
  width: 100%;
  max-width: 175px;
}
.uploadfile {
  cursor: pointer;
}
.user_role_table tr td {
  padding: 12px 11px !important;
  font-size: 14px;
  vertical-align: middle;
  color: #333;
}
.table-checkbox span {
  height: 26px;
  width: 26px;
  background-color: #f7f7f8;
  border: 1px solid #42424263;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
  flex: 0 0 26px;
  position: relative;
}
.table-checkbox input {
  display: none;
}
.table-checkbox input {
  display: none;
}
.table-checkbox input:checked + span:before {
  content: '';
  position: absolute;
  left: 8px;
  top: 5.5px;
  width: 8px;
  height: 12px;
  border: solid #aa0061;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.signatories-toggle {
  justify-content: flex-start !important;
  margin-bottom: 20px;
}
.signatories-toggle .sharing-title {
  margin-right: 10px;
}
.checkbox-card .container {
  width: auto !important;
  margin: 0 40px 0 0;
  padding-top: 5px;
  padding-bottom: 5px;
}
.checkbox-card .user-roles-box {
  flex-wrap: wrap;
}
.checkbox-card .container .checkmark {
  top: 3px;
}
.checkbox-card {
  background: rgb(217 217 217 / 40%);
  padding: 16px 19px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.custom-menu-dots .dropdown-item {
  padding: 0.01rem 1rem !important;
}
.previewTitle {
  display: 'flex';
  align-items: center;
  justify-content: space-between;
}
.previewTitle > div {
  width: auto;
}
.userBox * {
  margin: 0;
}
.userBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 300px;
  background: #c4c4c426;
  padding: 8px 15px;
  border-radius: 4px;
}
.userBox > div {
  display: flex;
  align-items: center;
}
.userBox .user-role {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: rgba(51, 51, 51, 0.5);
}
.FileReop {
  text-decoration: none;
  color: #000;
}
h6.text-capitalize {
  color: var(--purple);
  font-size: 20px;
}
.create-by img {
  height: auto;
  width: 38px;
  border-radius: 100%;
  margin-left: 7px;
}
.bold-user-info {
  font-weight: 900 !important;
  color: #000 !important;
}
.error {
  font-size: 0.875em !important;
}
p.error {
  margin: 0;
}
.dynamic-form-check {
  margin: 0 !important;
  padding: 0 !important;
}
.child_info_field {
  margin-bottom: 0 !important;
}
.module-drop-down {
  display: flex;
  width: 100%;
}
.create_model_bar .forms-toogle .dropdown-toggle.btn.btn-primary {
  margin: 0;
}
.custom-menu-dots .dropdown-item {
  padding: 0.3rem !important;
  max-width: 110px;
  flex: 0 0 110px;
  flex-flow: wrap;
  font-size: 12px;
  white-space: pre-wrap;
  background-color: transparent;
}
.custom-menu-dots .dropdown-item svg {
  width: 20px;
}
.edit-module {
  display: flex;
  flex: 0 0 50px;
  max-width: 50px;
  align-items: center;
}
.custom-menu-dots .edit-module .dropdown-item {
  padding: 0 !important;
  max-width: 25px;
  flex: 0 0 auto;
}
.custom-menu-dots .edit-module .dropdown-item svg,
.custom-menu-dots .edit-module .dropdown-item.active svg {
  color: var(--primary);
  opacity: 0.5;
  max-width: 15px;
}
.custom-menu-dots .edit-module .dropdown-item.active.tab-trash svg,
.custom-menu-dots .edit-module .dropdown-item.tab-trash svg {
  max-width: 13px;
}
.custom-menu-dots .edit-module .dropdown-item.active:hover svg,
.custom-menu-dots .edit-module .dropdown-item:hover svg {
  opacity: 1;
}
.flex_wrap_checkbox .dynamic-form-check {
  flex-wrap: wrap;
  justify-content: flex-start !important;
}
.flex_wrap_checkbox .dynamic-form-check label.container {
  margin-left: 0;
  display: inline-block;
  width: auto;
}
.flex_wrap_radio {
  flex-wrap: wrap;
  justify-content: flex-start !important;
}
.flex_wrap_radio .new-form-radio-box {
  margin-left: 0;
  display: inline-block;
  width: auto;
  margin-bottom: 10px;
}
.set-layout-row h6.text-capitalize {
  margin: 10px 0 7px 0;
}
p.error:empty {
  display: none;
}
.form-label {
  margin-bottom: 0 !important;
  padding: 0 !important;
}
.form-input-section {
  margin-bottom: 15px;
}
.main-form-heading-title label {
  font-size: 21px !important;
  padding-bottom: 7px !important;
}
.main-form-text-heading-title label {
  font-size: 19px !important;
  padding-bottom: 9px !important;
}
.main-form-sub-heading-title label {
  font-size: 15px !important;
  padding-bottom: 9px !important;
}

.custom-menu-dots .file_repo_edit .dropdown-item {
  padding: 0.3rem !important;
  max-width: 150px;
  flex: 0 0 110px;
  flex-flow: wrap;
  font-size: 12px;
  white-space: pre-wrap;
  background-color: transparent;
}

@media only screen and (max-width: 1140px) {
  .new-form-radio-box {
    margin: 0 20px 20px 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .responses-header-row {
    flex-wrap: nowrap !important;
    padding-right: 0 !important;
  }
  .responses-header-right {
    padding-top: 0 !important;
  }
  .responses_model .modal-dialog {
    min-width: auto;
  }
  .modal-body {
    padding: 20px 15px; /*overflow:hidden;overflow-x:scroll*/
  }
  .user_list_wrp {
    min-width: calc(700px - 24px);
  }
  /*.extra-btn .btn-btn-outline,.extra-btn .ctaact{min-width:110px!important}*/
}
@media only screen and (max-width: 575px) {
  .responses-header-row {
    flex-wrap: wrap !important;
    padding-right: 15px !important;
  }
  .responses-header-right {
    padding-top: 10px !important;
  }
  .user_role_table {
    overflow-x: scroll;
  }
  .user_role_table table {
    margin-top: 18px;
    width: 700px;
  }
  .form_setting {
    margin-top: 0;
  }
}
@media only screen and (max-width: 375px) {
  .responses_model .modal-header .modal-title h1 {
    font-size: 18px;
  }
  .content-icon-section img {
    width: 35px;
    height: 35px;
  }
  .content-icon-section {
    flex: 0 0 auto !important;
  }
  .create-other {
    padding: 15px 10px;
    flex-wrap: wrap;
  }
  .applicable_section_card {
    padding: 12px 15px 15px;
  }
}
